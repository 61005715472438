<template>
    <div v-if="$can('read','client_recruiter')">
        <app-collapse class="mt-1 mb-1" style="border-radius: 150px !important">
            <app-collapse-item title="Filter">
                <b-card>
                    <b-row>
                        <b-col md="4">
                            <b-form-group
                                label="Select Client"
                                label-for="select-client">
                                <v-select
                                    v-model="searchFilters.clientSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="clientList"
                                    :reduce="(option) => option.id"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                label="Select Account Manager"
                                label-for="select-recruiter">
                                <v-select
                                    v-model="searchFilters.accountManagerSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="accountManagerList"
                                    :reduce="(option) => option.emp_id"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-button class="mt-2" variant="primary"  
                                @click="getRecruitersList()" 
                            >Filter</b-button>
                            <!-- <span class="px-1 mt-1" @click="clearFilter()">Clear Filter</span> -->
                        </b-col>
                        <b-col md="2">
                            <b-button class="mt-2" variant="secondary"  
                                @click="clearFilter()" 
                            >Clear Filter</b-button>
                            <!-- <span class="px-1 mt-1" @click="clearFilter()">Clear Filter</span> -->
                        </b-col>
                    </b-row>
                </b-card>
            </app-collapse-item>
        </app-collapse>                    
            
        <div class="text-right" v-if="$can('add','client_recruiter')">  
          <router-link :to="'/apps/add-expense'" >
            <button type="button" class="btn btn-success mb-1 mr-1">Add Client POC</button>
          </router-link>
        </div>
        <b-card>
            <b-row>
                <b-col md="6"> <b> Total Rows: {{ totalRows }}</b></b-col>
                <b-col md="6">
                    <div class="text-right">
                        <b-input-group size="sm" class="mb-1 mr-1">
                            <b-form-input
                                id="filterInput"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                                trim
                                style="height: 40px;"
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div> 
                </b-col>
            </b-row>
            
               
            <h4 class="text-primary text-center"> Recruiter List </h4>
            <b-table class="font-weight-bolder"
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="recruiterList"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  style="font-size: 0.8rem; font-weight: 400; line-height: 1.3;"
                >
                    
                
            </b-table>
        </b-card>
    </div>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardText, BCardTitle, BCardBody, BMedia, BMediaAside, BMediaBody, BButton, BSpinner, BTable, BBadge, BPagination, BAvatar, VBTooltip, BInputGroup, BFormInput, BInputGroupAppend, BFormGroup } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import clientRecruiterListService from '@/services/client-recruiter-list-service'
import clientService from '@/services/client-service'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    data() {
      return {
        fields: [
            { key: 'id', label: 'ID', sortable: true },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'client.client_name', label: 'Client', sortable: true },
            { key: 'contactNo', label: 'ContactNo', sortable: true },
            { key: 'emailId', label: 'EMail', sortable: true },
            { key: 'position', label: 'position', sortable: true },
            { key: 'reportingManager', label: 'Reporting Manager', sortable: true },
            { key: 'location', label: 'Location', sortable: true },
            { key: 'recruiter', label: 'Account-Manager', sortable: true },
            { key: 'skillSet', label: 'Skill Set', sortable: true },
        ],
        pageOptions: [10,20, 30, 50, 100, 200],
        totalRows: 0,
        perPage: 50,
        currentPage: 1,
        sortBy: '',
        sortDesc: '',
        sortDirection: 'desc',
        recruiterList: [],
        // filteredList: [],
        error: null,
        accessCheck: false,
        role: null,
        name: null,
        filter: null,
        filterOn: [],
        clientList: [],
        accountManagerList: [],
        searchFilters: {
            clientSelected: '',
            accountManagerSelected: '',
            filter: ''
        },
        allUsers: []
    //   loggedInUser: null
      }
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BSpinner,
        BTable,
        BBadge,
        BPagination,
        AppCollapse,
        AppCollapseItem,
        BCardHeader, BCardText, BCardTitle, BCardBody, BMedia, BMediaAside, BMediaBody, BAvatar, BInputGroup, BFormInput, BInputGroupAppend, BFormGroup,
        vSelect//BMedia, BMediaAside, BAvatar, BMediaBody
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    
  },
  watch: {

  },
  created() {

    // const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    this.empId = JSON.parse(localStorage.getItem('userData')).emp_id
    // const userMgrid = JSON.parse(localStorage.getItem('userData')).mgr_id
    const userData = JSON.parse(localStorage.getItem('userData'))
    // this.accessCheck = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' ? true : true
    // console.log(this.accessCheck)
    // this.role = userData.roles[0].toLowerCase()
    this.name = userData.first_name + ' ' + userData.last_name
    // if(!this.accessCheck ) {
    //     this.$router.push({ name: "misc-not-authorized" });
    // }
    this.allUsers = JSON.parse(localStorage.getItem('allusers'))
    

  },
  methods: {
    getRecruitersList() {
        if(!this.$can('read','client_recruiter')) {
            return
        }
        const recruiter = this.searchFilters.accountManagerSelected ? this.searchFilters.accountManagerSelected : ''
        const client = this.searchFilters.clientSelected ? this.searchFilters.clientSelected : ''


        clientRecruiterListService.getAllRecruiters(recruiter,client).then(res =>{
        if(res.status === "OK") {
            this.recruiterList = res.data
            // this.filteredList = res.data 
            this.totalRows = this.recruiterList.length
            console.log(res)
            const selectedColumns = [ 'recruiter'];
            // const selectedColumnsClient = [ 'client']
            
            const recruiters = this.recruiterList.map(recruiter => {
                return selectedColumns.reduce((acc, column) => {
                    acc[column] = recruiter[column];
                    return acc;
                }, {});
            });
            const uniqueRecruiters = recruiters.filter((arr, index, self) =>
            index === self.findIndex((t) => (t.recruiter === arr.recruiter)))

            uniqueRecruiters.forEach(emp => {
                const rec = this.allUsers.filter(item => item.emp_id === emp.recruiter)[0]
                // const rec_name = rec.title //rec[0].first_name + ' ' + rec[0].last_name
                this.accountManagerList.push({"title": rec.title, "emp_id": rec.emp_id})
                
            })
            //console.log(uniqueRecruiters,this.accountManagerList)
            
            this.clientList = this.recruiterList.map(item => {
                return { title: item.client.client_name, id: item.client.id };
            });

            this.clientList = this.clientList.filter((arr, index, self) =>
            index === self.findIndex((t) => (t.title === arr.title && t.id === arr.id)))

            
            console.log(this.accountManagerList, this.clientList)
        }
        })
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },
    clearFilter() {
        this.getRecruitersList()
        this.searchFilters.clientSelected = '';
        this.searchFilters.accountManagerSelected = '';
    }
    // filterClientRecruiterList() {
    //     console.log(this.searchFilters)
    // }
    
    
    
  },
  updated: function () {
    //this.getExpensesList()
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
  },
  mounted() {
    this.getRecruitersList()
    // clientService.getClients().then(res => {
    //         this.clientList = res.data
    //         console.log(this.clientList)
    //     })
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
.demo-inline-spacing {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
</style>